body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* General style for your thesis sections */
.thesis-section {
  margin-bottom: -5px;  /* Adjust the spacing as needed */
}

/* Reset margins for p elements within specific containers to avoid double spacing */
.thesis-section p {
  margin-top: 0;
  margin-bottom: 0;
}

/* Style for analysis blocks */
.analysis-block {
  transition: opacity 0.5s ease-out;
  white-space: pre-wrap;
  margin-bottom: 15px;  /* Consistent with other sections */
}

body {
  background-color: #f8f7f7; /* Light grey background */
}

.companyPageMini * {
  max-width: 100%; /* Ensures no child can be wider than this component */
}

@media (max-width: 599px) {
  .left-panel {
    display: none;
  }
}

@media (min-width: 600px) {
  .left-panel {
    display: flex;
    flex: 1;
    background-color: #f0f0f0;
    align-items: 'center';
    justify-content: 'flex-end';
    flex-direction: 'column';
    padding: '20px';
  }
}